<template>
  <div id="topic">
    <div class="main">
      <div class="btnList">
        <div class="goTop" @click="toTop()">
          <img src="../../img/上课/7.png" alt="" title="回到顶部" />
        </div>
      </div>
      <div class="topicList">
        <div class="topicCode" v-show="num == 2">
          <img
            src="../../img/上课/课程大厅 扫码答题.png"
            alt=""
            style="width: 80%"
          />
        </div>
        <ul class="topiclists" v-show="num != 2">
          <li v-for="(item, index) in topic" :key="index">
            <div class="top">
              <div class="title">
                <b>{{ index + 1 }}</b
                >. <span v-show="item.type == 0">选择题</span
                ><span v-show="item.type != 0">判断题</span>

                <div class="titletopic" v-html="item.question"></div>
              </div>
              <ul>
                <li>
                  <div>A</div>
                  <span v-html="item.a"> </span>
                </li>
                <li>
                  <div>B</div>
                  <span v-html="item.b"> </span>
                </li>
                <li>
                  <div>C</div>
                  <span v-html="item.c"> </span>
                </li>
                <li>
                  <div>D</div>
                  <span v-html="item.d"></span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      num: 0,
      topicLists: 1,
      topic: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  updated() {},
  methods: {
    getinfo() {
      util.post(
        "/questionnaireList",
        { catalog_file_id: this.$route.query.dataid },
        (res) => {
          this.topic = res.data;
          console.log(res.data);
        }
      );
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            300;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>
<style lang="less" scoped>
#topic {
  height: calc(100% - 11px);
  .main {
    width: 1260px;
    height: 100%;
    margin: 11px auto;
    font-size: 20px;
    .btnList {
      position: fixed;
      right: 300px;
      bottom: 69px;
      width: 64px;
      text-align: center;
    }
    .topicCode {
      width: 1260px;
      height: 100%;
      background-color: #fff;
    }
    .topiclists {
      margin-right: 40px;
      > li {
        background-color: #fff;
        padding: 48px 131px 56px 74px;

        margin-bottom: 5px;
        .top {
          .title {
            font-weight: 400;
            color: #2c2c2c;
            b {
              font-weight: 400;
            }
            .titletopic {
              display: inline-block;

              /deep/img {
                width: 100px !important;
                vertical-align: top;
                margin: 0 2px;
              }
            }

            span {
              display: inline-block;
              width: 56px;
              height: 22px;
              line-height: 22px;
              background: #b3c9ff;
              border-radius: 2px;
              font-size: 14px;
              font-weight: 400;
              color: #2769e1;
              text-align: center;
            }
          }
          ul {
            // margin-bottom: 61px;
            li {
              display: flex;
              // align-items: center;
              margin-top: 23px;
              div {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // text-align: center;
                margin-right: 15px;
                // width: 28/96in;
                height: 28/96in;
                // line-height: 28/96in;
                border: 1px solid #cdcdcd;
                border-radius: 50%;
                padding: 0 6/96in;
              }
              /deep/p {
                display: flex;
                align-items: center;
              }
              /deep/img {
                width: 100px !important;
                vertical-align: top;
                margin: 0 2px;
              }
            }
          }
        }
      }
    }
  }
}
</style>